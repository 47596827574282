import React from "react"
import { Link } from "gatsby"

export default function NavBarDropdown({ content, children }) {
  return (
    <div className="navbar-item has-dropdown is-hoverable">
      <a className="navbar-link">{children}</a>

      <div className="navbar-dropdown is-right">
        {content.map(({ node }, index) => {
          return (
            <Link
              className="navbar-item"
              key={node.fields.slug}
              to={node.fields.slug}
            >
              {node.frontmatter.title}
            </Link>
          )
        })}
      </div>
    </div>
  )
}
