import React from "react"
import { Link } from "gatsby"

export default function Footer() {
  return (
    <footer className="footer">
      <div className="content has-text-centered">
        <p>
          <Link to="/legal/impressum">Impressum</Link> &mdash;{" "}
          <Link to="/legal/datenschutz">Datenschutz</Link>
        </p>
      </div>
    </footer>
  )
}
