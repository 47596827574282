import React from "react"

import Hero from "../components/Hero"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"

// import CookieConsent from "../components/CookieConsent"

export default function Layout({ children }) {
  return (
    <div>
      <NavBar />
      <Hero />
      <section className="section">{children}</section>
      <Footer />
      {/* <CookieConsent /> */}
    </div>
  )
}
