import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import { Location } from "@reach/router"

import NavBarDropdown from "../components/NavBarDropdown"

export default function NavBar() {
  const data = useStaticQuery(
    graphql`
      query {
        allNavbarYaml(filter: { visible: { eq: true } }) {
          edges {
            node {
              key
              title
              logo
            }
          }
        }

        allMdx(
          filter: { frontmatter: { visible: { eq: true } } }
          sort: { order: ASC, fields: frontmatter___order }
        ) {
          group(field: fields___section) {
            fieldValue
            edges {
              node {
                fields {
                  slug
                }
                frontmatter {
                  title
                }
              }
            }
          }
        }
      }
    `
  )

  const navBarItems = data.allNavbarYaml
  const groupedContents = data.allMdx.group.reduce((obj, item) => {
    return { ...obj, [item.fieldValue]: item.edges }
  }, {})
  const [isActive, setisActive] = React.useState(false)

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <Link className="navbar-item" to="/">
          <Location>
            {({ _, location }) => {
              const key = location.pathname.split("/")[1]
              const relevant_items = navBarItems.edges.filter(
                (item, index) => item.node.key === key
              )
              if (relevant_items.length === 1) {
                const current_item = relevant_items[0].node
                const logo_path = `/${current_item.logo}.jpg`
                return <img src={logo_path} alt="macht-sachen.de Logo" />
              } else {
                return <img src="/logo_sachen.jpg" alt="macht-sachen.de Logo" />
              }
            }}
          </Location>
        </Link>
        <a
          onClick={() => {
            setisActive(!isActive)
          }}
          role="button"
          className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarMenu"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div
        id="navbarMenu"
        className={`navbar-menu ${isActive ? "is-active" : ""}`}
      >
        <div className="navbar-start" />
        <div className="navbar-end">
          {navBarItems.edges
            .filter((item, index) => groupedContents[item.node.key])
            .map(function (item, indes) {
              return (
                <NavBarDropdown
                  key={item.node.key}
                  content={groupedContents[item.node.key]}
                >
                  {item.node.title}
                </NavBarDropdown>
              )
            })}
        </div>
      </div>
    </nav>
  )
}
