import React from "react"

export default function Hero() {
  return (
    <section
      className="hero is-primary is-medium"
      style={{
        background: `url("/hero.jpg") left center`,
        backgroundSize: `cover`,
        backgroundPositionX: `left`,
      }}
    >
      <div className="hero-body"></div>
    </section>
  )
}
